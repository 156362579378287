import React, { createElement } from 'react'
import { TrustedHtml, renderWithLocale } from 'shared'
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom'

export const mountOnId = <P extends {}>(
  component: React.ComponentType<P>,
  domId: string,
  data?: P,
  withLocale = true
) => {
  const domNode = document.getElementById(domId)
  if (domNode) {
    //Todo: Cast non string properties to their respective types (e.g. numbers are currently not real numbers but strings)
    const newElement = createElement(component, data ?? (domNode.dataset as P))
    withLocale ? renderWithLocale(newElement, domNode) : render(newElement, domNode)
  }
}

export const mountOnDataBehavior = <P extends {}>(component: React.ComponentType<P>, behavior: string, data?: P) => {
  const nodes: NodeListOf<HTMLElement> = document.querySelectorAll(`[data-behavior=${behavior}]`)
  Array.from(nodes).forEach((node) => {
    // We remove the attribute to guard that we select it multiple times.
    // But we want to keep the info about the component in an special atribute.
    node.setAttribute('data-info-initialized-behavior', node.getAttribute('data-behavior') || '')
    node.removeAttribute('data-behavior')
    node.classList.remove('invisible')
    const { behavior: _, ...dataAttributes } = node.dataset
    // TODO: Check if the sanitization does not affect anything in the content pages or any banner coming from the backoffice and the likes
    const children =
      node.dataset.withChildren !== undefined && node.innerHTML ? (
        !node.dataset.skipSanitization ? (
          <TrustedHtml as="div">{node.innerHTML}</TrustedHtml>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: node.innerHTML }}></div>
        )
      ) : undefined
    const newElement = createElement(component, data ?? (dataAttributes as P), children)
    renderWithLocale(newElement, node)
  })
}

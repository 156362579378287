import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '../button'
import { Modal, TrustedHtml } from 'shared'
import { ModalTriggerProps } from './types'

export const ModalWithTrigger = ({
  cssClass,
  trigger,
  title,
  children,
  buttonTriggered,
  content = '',
  size,
  closeButtonText,
  id,
  cancelButtonId,
}: ModalTriggerProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const hideModal = useCallback(() => {
    setShowModal(false)
    setIsModalOpen(false)
  }, [])
  const openModal = () => {
    setShowModal(true)
  }

  const onShowModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  useEffect(() => {
    if (!isModalOpen) return
    if (!cancelButtonId) return

    const modal = document.getElementById(id)
    if (!modal) return
    const cancelButton = modal.querySelector('.close-modal')
    if (!cancelButton) return

    cancelButton.addEventListener('click', hideModal)

    return () => {
      document.removeEventListener('click', hideModal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelButtonId, isModalOpen])

  return (
    <>
      {buttonTriggered ? (
        <button className={cssClass || 'btn btn-large btn-danger'} onClick={openModal}>
          {trigger}
        </button>
      ) : (
        <button type="button" className={cssClass || 'simulated-link'} onClick={openModal}>
          {trigger}
        </button>
      )}

      <Modal
        id={id}
        onHide={hideModal}
        show={showModal}
        header={title}
        size={size}
        onShow={onShowModal}
        footer={
          closeButtonText ? (
            <Button onClick={hideModal} className="btn-large btn-primary">
              {closeButtonText}
            </Button>
          ) : null
        }
      >
        <TrustedHtml as="div">{content}</TrustedHtml>
        {children}
      </Modal>
    </>
  )
}

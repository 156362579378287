import Cookies, { CookieChangeListener } from 'universal-cookie'
import { TRACKING_COOKIE_NAME } from 'shared'
import { useCallback, useEffect, useState } from 'react'

type TrackingCookieType = 'accepted' | 'rejected' | 'edit' | null

export const useTrackingCookie = (cookies: Cookies) => {
  const [cookie, setCookie] = useState<TrackingCookieType>(cookies.get(TRACKING_COOKIE_NAME))

  const handleCookieChange: CookieChangeListener = useCallback((cookie) => {
    if (cookie.name === TRACKING_COOKIE_NAME) {
      setCookie(cookie.value)
    }
  }, [])

  useEffect(() => {
    cookies.addChangeListener(handleCookieChange)

    return () => {
      cookies.removeChangeListener(handleCookieChange)
    }
  }, [handleCookieChange, cookies])

  return cookie
}

export type { TrackingCookieType }

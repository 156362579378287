import React from 'react'
import classNames from 'classnames'
import styles from './admin_box.module.css'

type AdminBoxVariant = 'dark' | 'light'
type AdminBoxProps = {
  children?: React.ReactNode
  customTitle?: string
  linkText?: string
  linkUrl?: string
  quickAccessBox?: boolean
  variant?: AdminBoxVariant
  withShadow?: boolean
}

export const AdminBox = ({
  children,
  customTitle,
  linkText,
  linkUrl,
  quickAccessBox,
  variant,
  withShadow = false,
}: AdminBoxProps) => {
  return (
    <div
      data-testid="admin-box"
      className={classNames([
        styles.adminBox,
        {
          [styles.variantDark as string]: variant === 'dark',
          [styles.shadow!]: withShadow,
        },
      ])}
    >
      <div className={styles.header}>{customTitle || i18n.t('shared.admin_box.header')}</div>
      <div className={classNames([quickAccessBox ? styles.quickAccessBoxContent : styles.content])}>
        {children}
        {linkText && linkUrl && (
          <a href={linkUrl} className="btn btn-large btn-primary">
            {linkText}
          </a>
        )}
      </div>
    </div>
  )
}

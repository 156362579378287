/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line import/no-named-as-default
import posthog, { type CaptureOptions, PostHog, PostHogConfig, Properties } from 'posthog-js'
import {
  Analytics,
  CleanupFunction,
  Config,
  type DonateButtonSafariPaypalLoadingErrorData,
  DonationAmountChangedData,
  DonationButtonClickedData,
  DonationCompletedData,
  DonationErrorMessageDisplayed,
  type DonationFormReceiverIdentification,
  DonationSubmittedData,
  FeatureFlagCallback,
  FeatureFlagData,
  FeatureFlagKey,
  FeatureFlagValue,
  type PaymentMethodChangedData,
  type StreambotSettingsModifiedData,
  type StreambotUrlCopiedData,
} from './types'
import { POSTHOG_URL } from './constants'
import { normalizeData } from './utils'

const OptInState = {
  NotSet: 'not-set', // The status of user the decision is not yet known
  Pending: 'pending', // Pending user decision
  OptedIn: 'opted-in',
  OptedOut: 'opted-out',
} as const
type OptInState = (typeof OptInState)[keyof typeof OptInState]

const InitialisationState = {
  Initialised: 'initialised',
  NotInitialised: 'not-initialised',
} as const

type InitialisationState = (typeof InitialisationState)[keyof typeof InitialisationState]

type AnalyticsState = {
  initialisation: InitialisationState
  optInOut: OptInState
  enabled: boolean
}

type Call = { call: () => void; prioritise: boolean }

// eslint-disable-next-line import/no-unused-modules
export class ClientSideAnalytics implements Analytics {
  private state: AnalyticsState = {
    initialisation: InitialisationState.NotInitialised,
    optInOut: OptInState.NotSet,
    enabled: true,
  }

  private featureFlags: FeatureFlagData = {}

  private interval: NodeJS.Timeout | undefined = undefined

  private callBuffer_: Array<Call> = []

  private queueCall(call: () => void, prioritise: boolean = false) {
    if (!this.state.enabled) {
      return
    }
    if (this.isInitialized() && (prioritise || this.state.optInOut === OptInState.OptedIn)) {
      return call()
    }
    this.callBuffer_.push({ call, prioritise })
    this.callBuffer_.sort((a, b) => +b.prioritise - +a.prioritise)
  }

  private ingestBuffer() {
    if (!this.callBuffer_.length) {
      return
    }
    const copy = [...this.callBuffer]
    this.callBuffer_ = []
    copy.forEach(({ call }) => call())
  }

  private handleQueuedCalls() {
    if (typeof this.interval !== 'undefined') {
      return
    }
    if (this.isInitialized()) {
      return this.ingestBuffer()
    }
    this.interval = setInterval(() => {
      if (this.isInitialized()) {
        this.cancel()
        this.ingestBuffer()
      }
    }, 100)
  }

  private sendEvent(name: string, data: Properties, options?: CaptureOptions) {
    if (this.state.optInOut === OptInState.OptedOut || this.state.optInOut === OptInState.Pending) {
      return
    }
    if (this.state.optInOut === OptInState.NotSet) {
      return this.queueCall(() => {
        this.sendEvent(name, data)
      })
    }
    const normalizedData = normalizeData(data)
    this.posthog.capture(name, normalizedData, options)
  }

  private capture(name: string, data: Properties, options?: CaptureOptions) {
    if (this.posthog?.config?.debug) {
      console.log('Analytics.capture', name, data, options, this.state)
    }
    if (this.state.optInOut !== OptInState.OptedIn && this.state.optInOut !== OptInState.NotSet) {
      return
    }
    this.queueCall(() => this.sendEvent(name, data, options))
  }

  /**
   * @returns the readonly call buffer
   */
  get callBuffer() {
    return this.callBuffer_ as ReadonlyArray<Readonly<Call>>
  }

  constructor(private posthog: PostHog) {}

  /**
   * Initializes the integration, call as soon as possible
   * @param config {Config} Configuration object
   */
  initialize(config: Config) {
    this.featureFlags = config.bootstrapData?.featureFlags ?? {}
    if (this.state.initialisation === InitialisationState.Initialised) {
      return
    }
    this.state.initialisation = InitialisationState.Initialised
    if (!config.enable) {
      this.state.enabled = false
      console.log('PostHog is disabled in this environment.')
      return
    }
    const cfg = {
      api_host: POSTHOG_URL,
      autocapture: false,
      opt_out_capturing_by_default: true,
      bootstrap: config?.bootstrapData,
    } satisfies Partial<PostHogConfig>

    if (!config.apiKey) throw new Error('PostHog API key is missing.')
    this.state.enabled = true
    this.posthog.init(config.apiKey, cfg)

    if (process.env.NODE_ENV === 'development' || config.debug) {
      this.posthog.debug()
      if (typeof window !== 'undefined') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(window as any).analytics = this
      }
    }
    if (config.debug) {
      console.log('Analytics.initialise', cfg, this.state)
    }
    this.handleQueuedCalls()
  }

  /**
   * Sets the opt-in state to pending and clears the events buffer, should be called as soon as the tracking-cookie is evaluated
   * and found not to be neither accepted, nor rejected
   */
  optInOutPending(): void {
    if (this.state.optInOut === OptInState.NotSet) {
      this.state.optInOut = OptInState.Pending
      // Remove all events
      this.callBuffer_ = this.callBuffer_.filter(({ prioritise }) => prioritise)
    }
  }

  /**
   * Sets the opt-in state to opted-out and clears the events buffer
   */
  optOut() {
    if (this.posthog?.config?.debug) {
      console.log('Analytics.optOut', this.state)
    }
    this.state.optInOut = OptInState.OptedOut
    this.callBuffer_ = []
    this.queueCall(() => {
      if (!this.posthog.has_opted_out_capturing()) {
        this.posthog.opt_out_capturing()
      }
    }, true)
  }

  /**
   * Sets the opt-in state to opted-in and triggers queued calls handling
   */
  optIn() {
    if (this.posthog?.config?.debug) {
      console.log('Analytics.optIn', this.state)
    }
    this.state.optInOut = OptInState.OptedIn
    this.queueCall(() => {
      if (!this.posthog.has_opted_in_capturing()) {
        this.posthog.opt_in_capturing()
      }
      // TODO: Remove when the following issue is fixed. See: https://github.com/PostHog/posthog-js/issues/336#issuecomment-1189153846 - DEV-25756
      this.posthog._start_queue_if_opted_in()
    }, true)
    this.handleQueuedCalls()
  }

  /**
   * @returns true if the integration is initialised
   */
  isInitialized() {
    return (
      this.state.initialisation === InitialisationState.Initialised &&
      (this.state.enabled ? this.posthog.__loaded : true)
    )
  }

  /**
   * @returns the distinct id of the user
   */
  getDistinctId() {
    return this.posthog.get_distinct_id()
  }

  donationButtonClicked(data: DonationButtonClickedData) {
    return this.capture('Donation Button Clicked', data)
  }

  donationFormOpened(data: DonationFormReceiverIdentification) {
    return this.capture('Donation Form Opened', data)
  }

  coDonationModalOpened(data: DonationFormReceiverIdentification) {
    return this.capture('Co-Donation Modal Opened', data)
  }

  donationSafariPaypalLoadingError(data: DonateButtonSafariPaypalLoadingErrorData) {
    return this.capture('Donation Safari Paypal Loading Error', data)
  }

  donationSubmitted(data: DonationSubmittedData) {
    return this.capture('Donation Submitted', data)
  }

  donationCompleted(data: DonationCompletedData) {
    return this.capture('Donation Completed', data, { send_instantly: true })
  }

  donationErrorMessageDisplayed(data: DonationErrorMessageDisplayed) {
    return this.capture('Donation Error Message Displayed', data)
  }

  coDonationSetToZero(data: DonationFormReceiverIdentification) {
    return this.capture('Co-Donation Set To Zero', data)
  }

  donationAmountChanged(data: DonationAmountChangedData) {
    return this.capture('Donation Amount Changed', data)
  }

  streambotUrlCopied(data: StreambotUrlCopiedData) {
    return this.capture('Streambot Url Copied', data)
  }

  streambotSettingsModified(data: StreambotSettingsModifiedData) {
    return this.capture('Streambot Settings Modified', data)
  }

  paymentMethodChanged(data: PaymentMethodChangedData) {
    return this.capture('Payment Method Changed', data)
  }

  /**
   * Cancels the interval that checks if the integration is initialised
   */
  cancel() {
    clearInterval(this.interval)
    this.interval = undefined
  }

  /**
   * Called whenever user changes
   */
  reset() {
    this.callBuffer_ = []
    // TODO: Check when is this relevant, ie cookie deletion
    // this.state = {
    //   ...this.state,
    //   optInOut: OptInState.NotSet,
    // }
    if (this.state.enabled) {
      this.queueCall(() => {
        this.posthog.reset()
      }, true)
    }
  }

  /**
   * Synchronously retrieves the current state of the feature flag
   * @param name {FeatureFlagKey} The name of the feature flag
   * @returns {FeatureFlagValue}
   */
  getFeatureFlag(name: FeatureFlagKey): FeatureFlagValue {
    if (!this.state.enabled || !this.isInitialized()) {
      return this.featureFlags[name]
    }
    return this.posthog.getFeatureFlag(name)
  }

  getFeatureFlags() {
    if (!this.state.enabled || !this.isInitialized()) {
      return this.featureFlags
    }
    return this.posthog.featureFlags.getFlagVariants()
  }

  /**
   * Attaches a listener to the feature flag
   * @param name {FeatureFlagKey} The name of the feature flag
   * @param callback {FeatureFlagCallback} The callback to be called whenever the feature flag changes
   * @returns {CleanupFunction} A function that removes the listener
   */
  listenToFeatureFlag(name: FeatureFlagKey, callback: FeatureFlagCallback): CleanupFunction {
    if (!this.state.enabled) {
      callback(this.featureFlags[name])
      return () => {}
    }
    return this.posthog.onFeatureFlags(() => {
      const featureFlagValue = this.posthog.getFeatureFlag(name)
      callback(featureFlagValue)
    })
  }
}

export default new ClientSideAnalytics(posthog)

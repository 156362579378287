function getDomain() {
  console.log('real getDomain')
  const hostname = window.location.hostname
  if (!hostname) return ''
  const hostnameArray = hostname.split('.')
  if (hostnameArray.length <= 2) return hostname

  return hostnameArray.slice(1).join('.')
}

export default getDomain

import AddProjectsIcon from '@betterplace/assets/images/icons/add_projects.svg'
import AlertsAndOverlaysIcon from '@betterplace/assets/images/icons/alerts_and_overlays.svg'
import AllSettingsIcon from '@betterplace/assets/images/icons/all_settings.svg'
import DocumentIcon from '@betterplace/assets/images/icons/document_icon.svg'
import EditFundraisingEventIcon from '@betterplace/assets/images/icons/edit_fundraising_event.svg'
import EditTitleImageIcon from '@betterplace/assets/images/icons/edit_title_image.svg'
import EuroIcon from '@betterplace/assets/images/icons/euro_icon.svg'
import ForwardDonationsIcon from '@betterplace/assets/images/icons/forward_donations.svg'
import React from 'react'
import SayThankYouIcon from '@betterplace/assets/images/icons/say_thank_you.svg'
import ShareIcon from '@betterplace/assets/images/icons/share_icon.svg'
import WriteNewsIcon from '@betterplace/assets/images/icons/write_news.svg'
import classNames from 'classnames'
import styles from './quick_access_button.module.css'
import { SharingGridButton, TrustedHtml } from 'shared'

const iconDictionary: { [key: string]: string } = {
  wirwunder: ShareIcon,
  nussbaum: ShareIcon,
  manage_payouts: EuroIcon,
  edit_project: EditFundraisingEventIcon,
  manage_needs: AddProjectsIcon,
  manage_images: EditTitleImageIcon,
  add_iframe: DocumentIcon,
  add_projects: AddProjectsIcon,
  alerts_and_overlays: AlertsAndOverlaysIcon,
  all_settings: AllSettingsIcon,
  edit_fundraising_event: EditFundraisingEventIcon,
  edit_title_image: EditTitleImageIcon,
  forward_donations: ForwardDonationsIcon,
  say_thank_you: SayThankYouIcon, // project and fe
  write_news: WriteNewsIcon, // project and fe
  edit_description: EditFundraisingEventIcon,
}

export function QuickAccessButton({ type, url }: { type: string; url: string }) {
  return (
    <a className={classNames(styles.iconButton, 'btn btn-large btn-outline-green text-center')} href={url}>
      <>
        <img className={styles.icon} src={iconDictionary[type]} alt="" />
        <TrustedHtml>{i18n.t(`admin_boxes.labels.${type}_html`)}</TrustedHtml>
      </>
    </a>
  )
}

export function QuickAccessSharingButton({ shareUrl }: { shareUrl: string }) {
  return (
    <SharingGridButton
      className={styles.iconButton}
      shareUrl={shareUrl}
      platforms={['facebook', 'twitter', 'link', 'whatsapp', 'messenger', 'instagram']}
      modalHeader={i18n.t('fundraising_events.show.basic_information.share_modal_headline')}
      buttonLabel={
        <>
          <img className={styles.icon} src={ShareIcon} alt="" />
          <TrustedHtml className={styles.labelLines}>
            {i18n.t('admin_boxes.labels.share_fundraising_event_html')}
          </TrustedHtml>
        </>
      }
      size="large"
      utmMedium="fepp_admin_box"
    />
  )
}
